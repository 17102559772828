import "./theme/app.css";
import "vue3-lottie/dist/style.css";

import { createApp } from "vue";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { registerSW } from "virtual:pwa-register";
import Vue3Lottie from "vue3-lottie";

import { Capacitor } from "@capacitor/core";
import { IonicConfig } from "@ionic/core";
import { IonicVue } from "@ionic/vue";
import { XCloud } from "@xcloud/api/dist/esm/base";

import App from "./App.vue";
import { firebaseConfig } from "./firebaseConfig";
import vueI18n from "./plugins/vueI18n";
import vueRouter from "./plugins/vueRouter";
import vueSentryPlugin, { SentryPluginOptions } from "./plugins/vueSentry";

if ("serviceWorker" in navigator && !Capacitor.isNativePlatform()) {
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  registerSW({
    onRegisteredSW(_, registration) {
      getToken(messaging, { vapidKey: import.meta.env.VITE_APP_VAPID_KEY, serviceWorkerRegistration: registration });
    },
  });
}

XCloud.BASE_URL = import.meta.env.VITE_APP_API_HOST;
createApp(App)
  .use(IonicVue, {
    rippleEffect: false,
  } as IonicConfig)
  .use(Vue3Lottie, { name: "Vue3Lottie" })
  .use(vueRouter)
  .use(vueI18n)
  .use(vueSentryPlugin, {
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    tracesSampleRate: import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE,
    siteURL: import.meta.env.VITE_APP_URL,
    enableReplays: false,
    environment: import.meta.env.MODE,
  } as SentryPluginOptions)
  .mount("#app");
